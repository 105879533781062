const EAlasanStatus = Object.freeze({
  DEFAULT: 0,
  BTS: 1,
  BKJ: 2,
  // OTH: 9,
});
const EAlasanStatuses = Object.freeze([
  {
    id: EAlasanStatus.DEFAULT,
    description: "",
  },
  {
    id: EAlasanStatus.BTS,
    description: "Berkas Tidak Sesuai yang Dipersyaratkan",
  },
  {
    id: EAlasanStatus.BKJ,
    description: "Berkas Kurang Jelas",
  },
]);
export { EAlasanStatus as default, EAlasanStatuses };
