<template>
  <v-dialog v-model="dialogShow" fullscreen>
    <BsdPdfViewer  @closeDialog="closeDialog" :itemFile="itemFile"></BsdPdfViewer>
  </v-dialog>
</template>

<script>
import BsdPdfViewer from "@/components/utils/CafePdfViewer.vue";

export default {
  name: "BsdPdfViewerDialog",
  components: {
    BsdPdfViewer,
  },
  data() {
    return {
      dialogShow: false,
      itemFile: "",
    };
  },

  props: {
    infoDialogTitle: String,
  },
  computed: {},
  methods: {
    showDialog(item) {
      this.dialogShow = !this.dialogShow;
      this.itemFile = item;
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    closeDialog() {
      this.dialogShow = false;
    },
  },
};
</script>

<style scoped>
</style>