<template>
  <v-dialog
    v-model="dialogShow"
    :max-width="validConfirmOptions.width"
    :style="{ zIndex: validConfirmOptions.zIndex }"
    @keydown.esc="dialogShow = false"
  >
    <v-card>
      <v-toolbar dark :color="validConfirmOptions.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ validConfirmOptions.title }}
        </v-toolbar-title>
      </v-toolbar>
      <!--      <v-container>-->
      <!--        <v-card-subtitle align="center" class="text-h6">{{ validConfirmOptions.message1 }}</v-card-subtitle>-->
      <!--        <v-card-subtitle align="center" class="text-h6">{{ validConfirmOptions.message2 }}</v-card-subtitle>-->
      <!--      </v-container>-->
      <p align="center" class="mt-2">
        {{ validConfirmOptions.message1 }}<br />{{
          validConfirmOptions.message2
        }}
      </p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" outlined text @click="dialogShow = false"
          >Cancel</v-btn
        >
        <v-btn color="green darken-1" outlined text @click="confirmValid"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  name: "CloseDialog",
  data() {
    return {
      dialogShow: false,
      validConfirmOptions: {
        title: "Konfirmasi Validasi Dokumen",
        message1: "Sudah ada Modifikasi, Lanjutkan Cancel?",
        message2: "",
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },

  props: [],
  computed: {},
  methods: {
    confirmValid: function () {
      // this.myConfirmDelete = false
      this.$emit("eventFromValidConfirmDialog", "OKE");
    },
    showDialog(message1, message2) {
      // console.log("from parent ");
      this.validConfirmOptions.message1 =
        message1 !== undefined ? message1 : "";
      this.validConfirmOptions.message2 =
        message2 !== undefined ? message2 : "";
      this.dialogShow = !this.dialogShow;
    },
    setDialogState(value) {
      console.log(value);
      this.dialogShow = false;
    },
  },
};
</script>
  
  <style scoped>
</style>