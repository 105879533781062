<template>
  <cafe-pdf
      style="background-color: white;"
    ref="pdf"
    @on-success="load"
    @on-scroll="scroll"
    :filePath="filePdf(itemFile)"
  >
    <template slot="header">
      <v-row class="px-2">
        <v-col cols="5">
          <v-row align="center">
            <v-col cols="1" align="center" justify="center">
              <v-btn
                icon
                small
                color="primary"
                :disabled="currentNum <= 1"
                @click="pre"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                dense
                hide-details=""
                type="number"
                :min="1"
                :max="pageNum"
                v-model.number="currentNum"
                @change="pageChange(currentNum)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" align="center" justify="center">
              <v-btn
                icon
                small
                color="primary"
                :disabled="currentNum >= pageNum"
                @click="next"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-col>
            <v-col cols="2" align="center" justify="center">
              <select v-model="scale" @change="setScale">
                <option value="auto">Auto</option>
                <option value="page-actual">Actual</option>
                <option value="page-fit">Fit</option>
                <option value="page-width">Full Width</option>
                <option value="0.50">50%</option>
                <option value="0.75">75%</option>
                <option value="1">100%</option>
                <option value="1.25">125%</option>
                <option value="1.50">150%</option>
                <option value="1.75">175%</option>
                <option value="2">200%</option>
                <option value="3">300%</option>
                <option value="4">400%</option>
              </select>
            </v-col>
            <v-col cols="1" align="center" justify="center">
              <v-btn fab small color="primary" @click="rotateLeft"
              ><v-icon color="white">mdi-rotate-left</v-icon></v-btn
              >
            </v-col><v-col cols="1" align="center" justify="center">
              <v-btn fab small color="primary" @click="rotateRight"
              ><v-icon color="white">mdi-rotate-right</v-icon></v-btn
              >
            </v-col>
            <v-col v-if="false" cols="1" align="center" justify="center">
              <v-btn fab small color="orange darken-2" @click="print"
                ><v-icon color="white">mdi-printer</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="1" align="end">
          <v-btn @click="closeDialog" icon>
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </cafe-pdf>
</template>

<script>
import cafePdf from 'cafe-pdf'
// import "cafe-pdf/package/cafe-pdf.css";
import FileService from "@/services/apiservices/file-service";

export default {
  name: "CafepdfViewer",
  props: {
    itemFile: Object,
  },
  data() {
    return {
      src: "https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf",
      pageNum: null,
      scale: "page-width",
      currentNum: 1,
      items: [
        { id: "auto", text: "Auto" },
        { id: "page-actual", text: "Actual" },
        { id: "page-fit", text: "Fit" },
        { id: "page-width", text: "Full Width" },
        { id: 0.5, text: "50%" },
        { id: 0.75, text: "75%" },
        { id: 1, text: "100%" },
        { id: 1.25, text: "125%" },
        { id: 1.5, text: "150%" },
        { id: 1.75, text: "175%" },
        { id: 2, text: "200%" },
        { id: 3, text: "300%" },
        { id: 4, text: "400%" },
      ],
    };
  },
  components: {
    cafePdf,
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    filePdf(item) {
      return FileService.file_url(item.fileName);
    },
    fileChanged() {
      this.file = this.src;
    },
    load(val) {
      this.pageNum = val;
    },
    setScale() {
      this.$refs.pdf.changeScale(this.scale);
    },
    scroll(val) {
      this.currentNum = val;
    },
    pre(){
      this.$refs.pdf.prePage();
    },
    next(){
      this.$refs.pdf.nextPage();
    },
    pageChange(val) {
      this.$refs.pdf.goToPage(val);
    },
    print() {
      this.$refs.pdf.printFile();
    },
    rotateRight() {
      this.$refs.pdf.rotateCW();
    },
    rotateLeft() {
      this.$refs.pdf.rotateCCW();
    }
  },
  mounted() {
    this.$refs.pdf.changeScale("page-width");
  },
};
</script>

<style>

</style>