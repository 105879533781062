<template>
  <v-card class="mt-4 mx-2 elevation-0">
    <ValidasiVerifikasiDoc></ValidasiVerifikasiDoc>
  </v-card>
</template>
  
  <script>
import ValidasiVerifikasiDoc from "@/components/admin_validasi/ValidasiVerifikasiDoc.vue";

export default {
  name: "ValidasiVerifikasiDocView",
  components: {
    ValidasiVerifikasiDoc,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
  <style scoped>
</style>