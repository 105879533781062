const EDitolak = Object.freeze({
  BTS: 1,
  TMS: 2,
  // OTH: 9,
});
const EDitolaks = Object.freeze([
  {
    id: EDitolak.BTS,
    description: "Berkas Tidak Sesuai",
  },
  {
    id: EDitolak.TMS,
    description: "Tidak Memenuhi Syarat",
  },
  // {
  //   id: EDitolak.OTH,
  //   description: "ALasan Lainnya",
  // },
]);
export { EDitolak as default, EDitolaks };
