import axios from "axios";
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL;

class FtRegistrasiFileService {
  getApiUrl() {
    return API_URL;
  }
  getAllFtRegistrasiFile() {
    return axios.get(API_URL + `getAllFtRegistrasiFile`, {
      headers: authHeader(),
    });
  }
  getAllFtRegistrasiFileContaining(page, pageSize, sortBy, order, search) {
    // return axios.get(API_URL + `getAllFtRegistrasiFile`, { headers: authHeaderMultipart() });
    return axios.get(
      API_URL +
        `getAllFtRegistrasiFileContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`,
      { headers: authHeader() }
    );
  }
  getFtRegistrasiFileById(id) {
    return axios.get(API_URL + `getFtRegistrasiFileById/${id}`, {
      headers: authHeader(),
    });
  }

  getAllFtRegistrasiFileByParent(id) {
    return axios.get(API_URL + `getAllFtRegistrasiFileByParent/${id}`, {
      headers: authHeader(),
    });
  }
  getAllFtRegistrasiFileByParentPublic(id) {
    return axios.get(API_URL + `public/getAllFtRegistrasiFileByParent/${id}`, {
      headers: authHeader(),
    });
  }


  getAllFtRegistrasiFileByFtRegistrasiBean(ftRegistrasiBean) {
    return axios.get(API_URL + `getAllFtRegistrasiFileByFtRegistrasiBean/${ftRegistrasiBean}`, {
      headers: authHeader(),
    });
  }
  getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType(ftRegistrasiBean, fileType) {
    return axios.get(API_URL + `getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType/${ftRegistrasiBean}/${fileType}`, {
      headers: authHeader(),
    });
  }
  getAllFtRegistrasiFileByFtRegistrasiBeanAndFileTypeAndFtemplateRegDokumenBean(ftRegistrasiBean, fileType, ftemplateRegDokumenBean) {
    return axios.get(API_URL + `getAllFtRegistrasiFileByFtRegistrasiBeanAndFileTypeAndFtemplateRegDokumenBean/${ftRegistrasiBean}/${fileType}/${ftemplateRegDokumenBean}`, {
      headers: authHeader(),
    });
  }


  updateFtRegistrasiFile(item) {
    return axios.put(API_URL + `updateFtRegistrasiFile/${item.id}`, item, {
      headers: authHeader(),
    });
  }
  createFtRegistrasiFile(item) {
    // console.log(JSON.stringify(item));

    return axios.post(API_URL + `createFtRegistrasiFile`, item, {
      headers: authHeader(),
    });
  }
  deleteFtRegistrasiFile(id) {
    return axios.delete(API_URL + `deleteFtRegistrasiFile/${id}`, {
      headers: authHeader(),
    });
  }

  deleteFtRegistrasiFileAvatarByParent(fkegiatanBean) {
    return axios.delete(
      API_URL + `deleteFtRegistrasiFileAvatarByParent/${fkegiatanBean}`,
      {
        headers: authHeader(),
      }
    );
  }
  createFtRegistrasiFileAvatar(item) {
    return axios.post(API_URL + `createFtRegistrasiFileAvatar`, item, {
      headers: authHeader(),
    });
  }

  deleteAllFtRegistrasiFile(itemIds) {
    return axios.delete(API_URL + `deleteAllFtRegistrasiFile`, {
      headers: authHeader(),
      data: itemIds,
    });
  }

  createFtRegistrasiFileMulti(payloadMulti) {

    return axios.post(API_URL + `createFtRegistrasiFileMulti`, payloadMulti, {
      headers: authHeader(),
    });
  }
}
export default new FtRegistrasiFileService();
